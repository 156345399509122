import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from 'components/Header';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import { CustomerProfile } from 'routes/customerProfile/CustomerProfile';
import { LoyaltyProgram } from 'routes/loyaltyProgram/LoyaltyProgram';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  AppVersion,
  AuthWrapper,
  EnvironmentBanner,
  useAuth,
} from '@fleet/shared';
import { appLoadingSelector } from 'features/loading/loadingSelectors';
import { Customers } from 'routes/customers/Customers';

export const Application = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const appLoading = useSelector(appLoadingSelector);

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(getBusinessEntities());
    }
  }, [auth.isAuthenticated, dispatch]);

  useEffect(() => {
    if (auth.isAuthenticated && currentBusinessEntityId) {
      dispatch(getClassifications());
    }
  }, [auth.isAuthenticated, dispatch, currentBusinessEntityId]);

  return (
    <>
      <Router>
        <AuthWrapper appLoading={appLoading}>
          <EnvironmentBanner envName={process.env.REACT_APP_ENVIRONMENT!} />
          <Header />
          <Switch>
            <Route path="/customers" component={Customers} />
            <Route path="/loyalty-program" component={LoyaltyProgram} />
            <Route path="/customer-profiles" component={CustomerProfile} />
          </Switch>
        </AuthWrapper>
      </Router>
      <AppVersion version={process.env.REACT_APP_VERSION!} />
    </>
  );
};
