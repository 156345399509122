import { FC, useCallback, useMemo } from 'react';
import {
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
} from '@fleet/shared';
import { Button } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useTable } from 'react-table';
import { formatDate } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerContractsTableLoadingSelector } from 'features/loading/loadingSelectors';
import {
  businessCustomerContractsFiltersSelector,
  businessCustomerContractsSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { BusinessCustomerContract } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { getBusinessCustomerContracts } from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransButton } from 'i18n/trans/button';
import { getContractReport } from 'features/businessCustomerContracts/businessCustomerContractService';

export const BusinessCustomerContractsTable: FC = () => {
  const contracts = useSelector(businessCustomerContractsSelector);
  const loading = useSelector(businessCustomerContractsTableLoadingSelector);
  const filter = useSelector(businessCustomerContractsFiltersSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useMemo(() => contracts?.items ?? [], [contracts]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (contracts) {
        const { limit = pageSize, offset } = contracts;
        return offset / limit;
      }
      return 0;
    },
    [contracts]
  );

  const link = useCallback(
    (row: Row<BusinessCustomerContract>) =>
      `/customers/business-customer-contracts/edit/${row.original.businessCustomer.id}/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<BusinessCustomerContract>>(
    () => [
      {
        accessor: 'contractNumber',
        Header: <TransTableHead i18nKey="contractNumber" />,
        Cell: ({ row }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {row.original.contractNumber}
          </Link>
        ),
      },
      {
        accessor: 'businessCustomer',
        Header: <TransTableHead i18nKey="businessCustomer" />,
        Cell: ({ row, value }) =>
          row.original.businessCustomer && value ? (
            <Link
              to={`/customers/business-customers/edit/${row.original.businessCustomer.id}`}
              onClick={tableRowClickEventWrapper}
            >
              {value.name}
            </Link>
          ) : (
            ' - '
          ),
      },
      {
        id: 'contractor',
        accessor: ({ contractor }) => contractor?.name,
        Header: <TransTableHead i18nKey="contractor" />,
      },
      {
        accessor: 'referenceNumber',
        Header: <TransTableHead i18nKey="referenceNumber" />,
      },
      {
        id: 'credit.limit',
        accessor: ({ credit }) => credit?.availableLimit,
        Header: <TransTableHead i18nKey="availableLimit" />,
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="startDate" />,
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="endDate" />,
      },
      {
        id: 'status',
        accessor: ({ status }) => status.name,
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    [link]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getBusinessCustomerContracts({ ...filter, ...paginationParams })
      ),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: contracts?.totalCount,
    },
    usePagination
  );

  return (
    <Loadable loading={loading}>
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <TableCaption>
              <Button
                startIcon={<Icon name="upload" />}
                sx={{ ml: 'auto', px: 1, py: 0 }}
                onClick={async () => await getContractReport(filter)}
              >
                <TransButton i18nKey="exportSelected" />
              </Button>
            </TableCaption>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </Loadable>
  );
};
