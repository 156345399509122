import { StrictMode, Suspense } from 'react';
import * as Sentry from '@sentry/react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { Loader, theme, ThemeProvider } from '@fleet/shared/mui';
import { Provider as AlertProvider } from 'react-alert';
import { alertProviderProps } from '@fleet/shared/components/Alert';
import { Application } from 'App';
import 'i18n';
import 'styles/index.scss';
import { initializeSentry } from 'helpers/sentryConfig';
import AxiosErrorsInterceptorWrapper from 'wrappers/AxiosErrorsInterceptorWrapper';
import { AuthProvider, baseOidcConfig } from '@fleet/shared';

initializeSentry();

render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader active size="fullscreen" />}>
          <Provider store={store}>
            <AuthProvider {...baseOidcConfig}>
              <AlertProvider {...alertProviderProps}>
                <AxiosErrorsInterceptorWrapper>
                  <Application />
                </AxiosErrorsInterceptorWrapper>
              </AlertProvider>
            </AuthProvider>
          </Provider>
        </Suspense>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
);
