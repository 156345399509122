import { FC, useCallback, useMemo } from 'react';
import {
  ExternalLink,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Row, usePagination, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import {
  businessCustomersFilterSelector,
  businessCustomersSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomersTableLoadingSelector } from 'features/loading/loadingSelectors';
import { useDispatch, useSelector } from 'store/utils';
import { BusinessCustomer } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { getBusinessCustomers } from 'features/businessCustomer/businessCustomerActions';

export const BusinessCustomerTable: FC = () => {
  const profiles = useSelector(businessCustomersSelector);
  const loading = useSelector(businessCustomersTableLoadingSelector);
  const filter = useSelector(businessCustomersFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useMemo(() => profiles?.items ?? [], [profiles]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (profiles) {
        const { limit = pageSize, offset } = profiles;
        return offset / limit;
      }
      return 0;
    },
    [profiles]
  );

  const link = useCallback(
    (row: Row<BusinessCustomer>) =>
      `/customers/business-customers/edit/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<BusinessCustomer>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="businessCustomer" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        accessor: 'referenceNumber',
        Header: <TransTableHead i18nKey="referenceNumber" />,
      },
      {
        accessor: 'registrationCode',
        Header: <TransTableHead i18nKey="registrationCode" />,
      },
      {
        id: 'owner.id',
        accessor: ({ owner }) => owner?.id,
        Header: <TransTableHead i18nKey="owner" />,
        Cell: ({ row }: { row: Row<BusinessCustomer> }) => (
          <ExternalLink
            path={`/Contacts/Organization/Edit/${row.original.owner.id}`}
            underline="none"
            content={row.original.owner.name}
          />
        ),
      },
      {
        id: 'status',
        accessor: ({ status }) => status?.name,
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    [link]
  );
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getBusinessCustomers({ ...filter, ...paginationParams })),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: profiles?.totalCount,
    },
    usePagination
  );

  return (
    <Loadable loading={loading}>
      <SearchResult results={data.length} loading={loading}>
        <Table
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </Loadable>
  );
};
