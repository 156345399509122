import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { CustomerProfile } from 'dto/customerProfile';
import {
  Icon,
  Loadable,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { useHistory } from 'react-router-dom';
import { CustomerProfileSearchForm } from 'routes/customerProfile/CustomerProfileSearchForm';
import {
  customerProfileFilterSelector,
  customerProfilesSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { customerProfileTableLoadingSelector } from 'features/loading/loadingSelectors';
import { getCustomerProfileList } from 'features/customerProfile/customerProfileActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransButton } from 'i18n/trans/button';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    minHeight: 'auto',
  },
}));

export const CustomerProfileTable: FC = () => {
  const classes = useStyles();
  const profiles = useSelector(customerProfilesSelector);
  const loading = useSelector(customerProfileTableLoadingSelector);
  const filter = useSelector(customerProfileFilterSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const getPage = useCallback(
    (pageSize: number) => {
      if (profiles) {
        const { limit = pageSize, offset } = profiles;
        return offset / limit;
      }
      return 0;
    },
    [profiles]
  );
  const data = useMemo(() => profiles?.items ?? [], [profiles]);

  const link = useCallback(
    (row: Row<CustomerProfile>) => `/customer-profiles/${row.original.id}`,
    []
  );

  const columns = useMemo<TableColumns<CustomerProfile>>(
    () => [
      {
        accessor: 'firstName',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {row.original.firstName + ' ' + row.original.lastName}
          </Link>
        ),
      },
      {
        accessor: 'email',
        Header: <TransTableHead i18nKey="email" />,
      },
      {
        accessor: 'phoneNumber',
        Header: <TransTableHead i18nKey="phone" />,
        Cell: ({ row, value }) => {
          const phoneAreaCode = row.original.phoneAreaCode || '372';

          if (phoneAreaCode && value) {
            return phoneAreaCode + ' ' + value;
          }

          if (value) {
            return value;
          }

          return null;
        },
      },
      {
        accessor: 'isMemberOfLoyaltyProgram',
        Header: <TransTableHead i18nKey="loyalty" />,
        Cell: ({ row }) =>
          row.values.isMemberOfLoyaltyProgram && <Icon name="check" />,
      },
    ],
    [link]
  );
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getCustomerProfileList({ ...filter, ...paginationParams })
      ),
    [dispatch, filter]
  );
  const getRowId = useCallback((row: CustomerProfile) => String(row.id), []);
  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
        pageSize: filter.limit ?? state.pageSize,
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: profiles?.totalCount,
      getRowId,
    },
    usePagination,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const selectedRowIds = useMemo(
    () => Object.keys(table.state.selectedRowIds),
    [table.state.selectedRowIds]
  );
  const hasSelectedRows = useMemo(
    () => Boolean(selectedRowIds.length),
    [selectedRowIds]
  );

  return (
    <Loadable loading={loading}>
      <CustomerProfileSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <TableCaption>
              <Stack direction="row" alignItems="center">
                <Button
                  startIcon={<Icon name="file" />}
                  sx={{ ml: 'auto', px: 1, py: 0 }}
                  disabled={!hasSelectedRows}
                >
                  <TransButton i18nKey="exportSelected" />
                </Button>
                <Button
                  startIcon={<Icon name="error-circle" />}
                  sx={{ px: 1, py: 0 }}
                  disabled={!hasSelectedRows}
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
              </Stack>
            </TableCaption>
          }
          table={table}
          classes={{ root: classes.root }}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </Loadable>
  );
};
