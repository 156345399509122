import { FC, useCallback, useMemo } from 'react';
import {
  AddButton,
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  RadioGroupField,
  SearchForm,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransHeader } from 'i18n/trans/header';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { customerProfileFilterSelector } from 'features/customerProfile/customerProfileSelectors';
import { CustomerProfileSearchFilter } from 'dto/customerProfile';
import {
  getCustomerProfileList,
  setCustomerProfileFilter,
} from 'features/customerProfile/customerProfileActions';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';
import { pick } from 'lodash';

export const CustomerProfileSearchForm: FC = () => {
  const dispatch = useDispatch();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(customerProfileFilterSelector);

  const onSubmit = useCallback(
    (values: CustomerProfileSearchFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getCustomerProfileList({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const defaultValues = useMemo(
    () => ({ organizationId: currentBusinessEntityId }),
    [currentBusinessEntityId]
  );

  const initialValues = useMemo(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const { form, handleSubmit } = useForm<CustomerProfileSearchFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setCustomerProfileFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [defaultValues, dispatch, filter, form]);

  return (
    <SearchForm
      title={<TransHeader i18nKey="customerProfile" />}
      action={
        <AddButton component={Link} to="/customer_profiles/create" disabled>
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={5}>
            <Grid item xs={1}>
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                name="organizationId"
              />
            </Grid>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField name="email" label={<TransField i18nKey="email" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="phoneNumber"
                label={<TransField i18nKey="phone" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="cardNumber"
                label={<TransField i18nKey="card" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="accountCode"
                label={<TransField i18nKey="loyaltyId" />}
              />
            </Grid>
            <Grid item xs="auto">
              <RadioGroupField
                name="isLoyaltyMember"
                label={<TransField i18nKey="loyalty" />}
                options="BOOL"
                inline
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
