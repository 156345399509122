import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.CARRIER,
  BusinessEntityType.RETAILER,
];

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  BONUS_SCHEME_DISCOUNT_TYPE = 'BONUS_SCHEME_DISCOUNT_TYPE',
  BONUS_SCHEME_GROUP = 'BONUS_SCHEME_GROUP',
  CLIENT_CONTRACT_CARD_STATUS = 'CLIENT_CONTRACT_CARD_STATUS',
  CLIENT_CONTRACT_STATUS = 'CLIENT_CONTRACT_STATUS',
  COMFORT_LEVEL = 'COMFORT_LEVEL',
  CONCESSION_TYPE = 'CONCESSION_TYPE',
  CREDIT_TYPE = 'CREDIT_TYPE',
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  DIETARY_REQUIREMENT = 'DIETARY_REQUIREMENT',
  FILE_TYPE = 'FILE_TYPE',
  FOR_LOCALIZATION = 'FOR_LOCALIZATION',
  ORGANIZATION_STATUS = 'ORGANIZATION_STATUS',
  TRANSACTION_TYPE = 'TRANSACTION_TYPE',
  TRANSACTION_STATUS = 'TRANSACTION_STATUS',
  SEAT_PREFERENCE = 'SEAT_PREFERENCE',
  SEX = 'SEX',

  //!ClassificationGroups
  BANKS = 'BANKS',
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
  VOUCHERS = 'VOUCHERS',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.BONUS_SCHEME_DISCOUNT_TYPE,
  ClassificationGroup.BONUS_SCHEME_GROUP,
  ClassificationGroup.CLIENT_CONTRACT_CARD_STATUS,
  ClassificationGroup.CLIENT_CONTRACT_STATUS,
  ClassificationGroup.COMFORT_LEVEL,
  ClassificationGroup.CONCESSION_TYPE,
  ClassificationGroup.CREDIT_TYPE,
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.DIETARY_REQUIREMENT,
  ClassificationGroup.FILE_TYPE,
  ClassificationGroup.FOR_LOCALIZATION,
  ClassificationGroup.ORGANIZATION_STATUS,
  ClassificationGroup.TRANSACTION_TYPE,
  ClassificationGroup.TRANSACTION_STATUS,
  ClassificationGroup.SEAT_PREFERENCE,
  ClassificationGroup.SEX,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;

export interface Service extends Classifier<number> {}

export interface Country extends Classifier<number> {
  countryCode: string;
  phoneAreaCode: string;
}

export interface City extends Classifier<number> {
  code: string;
  timezone: string;
  country: Classifier<number>;
  county: Classifier<number>;
}

export interface County extends Classifier<number> {
  timeZone: string;
  country: Classifier<number>;
}
