import { createReducer } from '@reduxjs/toolkit';
import {
  getBanks,
  getBusinessEntities,
  getCities,
  getClassificationGroups,
  getCounties,
  getCountries,
  getVouchers,
} from 'features/classification/classificationActions';
import {
  City,
  ClassificationGroup,
  ClassificationKey,
  Country,
  County,
  Service,
} from 'dto/classification';
import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntity } from '@fleet/shared/dto/businessEntity';

type ClassificationState = Record<ClassificationKey, Array<Classifier>> & {
  [ClassificationGroup.BANKS]: Array<Classifier>;
  [ClassificationGroup.BUSINESS_ENTITY]: Array<BusinessEntity<number>>;
  [ClassificationGroup.CITY]: Array<City>;
  [ClassificationGroup.COUNTRY]: Array<Country>;
  [ClassificationGroup.COUNTY]: Array<County>;
  [ClassificationGroup.VOUCHERS]: Array<Service>;
};
const initialState = {} as ClassificationState;

export const classificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getClassificationGroups.fulfilled, (state, action) => {
      action.payload.forEach((data) => (state[data.id] = data.classifications));
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      state.COUNTRY = action.payload;
    })
    .addCase(getCounties.fulfilled, (state, action) => {
      state.COUNTY = action.payload;
    })
    .addCase(getCities.fulfilled, (state, action) => {
      state.CITY = action.payload;
    })
    .addCase(getBusinessEntities.fulfilled, (state, action) => {
      state.BUSINESS_ENTITY = action.payload;
    })
    .addCase(getVouchers.fulfilled, (state, action) => {
      state.VOUCHERS = action.payload;
    })
    .addCase(getBanks.fulfilled, (state, action) => {
      state.BANKS = action.payload;
    });
});
