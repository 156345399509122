import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  businessCustomerModalSelector,
  businessCustomerModalSelectorFilterSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomersTableLoadingSelector } from 'features/loading/loadingSelectors';
import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  Icon,
  Loadable,
  Modal,
  SearchResult,
  SelectOwnerField,
  Table,
  TableColumns,
  TextField,
  useForm,
} from '@fleet/shared';
import {
  getBusinessCustomers,
  getBusinessCustomersForModal,
} from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomer, BusinessCustomerFilter } from 'dto/businessCustomer';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import {
  CardContent,
  Divider,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { TransTableHead } from 'i18n/trans/table';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { Classifier } from '@fleet/shared/dto/classifier';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { ALLOWED_BUSINESS_ENTITY_ROLES } from 'dto/classification';

interface FindBusinessCustomersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (businessCustomer: {
    customer: Classifier<number>;
    owner: { id: number };
  }) => void;
}

export const FindBusinessCustomersModal: FC<FindBusinessCustomersModalProps> =
  ({ onClose, isOpen, onSubmit }) => {
    const [selectedBusinessCustomer, setSelectedBusinessCustomer] = useState<
      { customer: Classifier<number>; owner: { id: number } } | undefined
    >();
    const dispatch = useDispatch();
    const businessEntities = useSelector(businessEntitiesSelector);
    const customers = useSelector(businessCustomerModalSelector);
    const loading = useSelector(businessCustomersTableLoadingSelector);
    const filter = useSelector(businessCustomerModalSelectorFilterSelector);

    const getPage = useCallback(
      (pageSize: number) => {
        if (customers) {
          const { limit = pageSize, offset } = customers;
          return offset / limit;
        }
        return 0;
      },
      [customers]
    );

    const data = useMemo(() => customers?.items ?? [], [customers]);

    const onSearchSubmit = useCallback(
      (values) =>
        formSubmit(async () => {
          (document.activeElement as HTMLInputElement)?.blur?.();
          await dispatch(
            getBusinessCustomersForModal({
              ...values,
              offset: 0,
            })
          ).unwrap();
        }),
      [dispatch]
    );

    const { form, submitting, handleSubmit } = useForm<BusinessCustomerFilter>({
      onSubmit: onSearchSubmit,
      initialValues: filter,
    });

    const columns = useMemo<TableColumns<BusinessCustomer>>(
      () => [
        {
          id: 'selection',
          width: 40,
          Cell: ({ row }: { row: Row<BusinessCustomer> }) => (
            <Radio
              onClick={() =>
                setSelectedBusinessCustomer({
                  customer: {
                    id: row.original.id,
                    name: row.original.name,
                  },
                  owner: {
                    id: Number(row.original.owner.id),
                  },
                })
              }
              size="small"
              checked={
                row.original.id === selectedBusinessCustomer?.customer.id
              }
            />
          ),
        },
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
        },
      ],
      [selectedBusinessCustomer?.customer.id]
    );

    const handlePageChange = useCallback(
      async (paginationParams: PaginationParams) =>
        await dispatch(
          getBusinessCustomers({ ...filter, ...paginationParams })
        ),
      [dispatch, filter]
    );

    const table = useTable(
      {
        data,
        columns,
        initialState: {
          pageSize: 10,
        },
        pageCount: -1,
        useControlledState: (state) => ({
          ...state,
          pageIndex: getPage(state.pageSize),
          pageSize: filter.limit ?? state.pageSize,
        }),
        manualPagination: true,
        onPageChange: handlePageChange,
        total: customers?.totalCount,
      },
      usePagination,
      useRowSelect
    );

    const handleReset = useCallback(() => {
      form.reset();
    }, [form]);

    const resetModal = useCallback(() => {
      setSelectedBusinessCustomer(undefined);
    }, []);

    return (
      <Modal
        onClose={() => {
          resetModal();
          onClose();
        }}
        title={<TransTitle i18nKey="businessCustomer" />}
        actionButton={
          <Button
            startIcon={<Icon name="check" size={16} />}
            variant="contained"
            type="submit"
            onClick={() => {
              onSubmit(selectedBusinessCustomer!);
              resetModal();
              onClose();
            }}
            disabled={submitting || !selectedBusinessCustomer}
          >
            <TransButton i18nKey="confirm" />
          </Button>
        }
        open={isOpen}
      >
        <FormProvider form={form}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              sx={{ alignItems: 'center' }}
              spacing={2}
              columns={2}
            >
              <Grid item xs={1}>
                <SelectOwnerField
                  businessEntities={businessEntities}
                  allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
                  name="organizationId"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField name="name" label={<TransField i18nKey="name" />} />
              </Grid>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" spacing={2}>
                  <FormControl label="&nbsp;">
                    <Button
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="text"
                      onClick={handleReset}
                    >
                      <TransButton i18nKey="resetFilters" />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button variant="contained" type="submit" icon="search">
                      <TransButton i18nKey="search" />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <Divider sx={{ marginTop: '24px', marginBottom: '16px' }} />
        <Loadable loading={loading}>
          <SearchResult results={data.length} loading={loading}>
            <Table
              getHeaderProps={(_, meta) => {
                const isSelectionColumn = meta.column.id === 'selection';
                if (isSelectionColumn) {
                  meta.column.width = 0;
                }
                return {};
              }}
              getCellProps={(_, meta) => {
                const isSelectionColumn = meta.cell.column.id === 'selection';
                return isSelectionColumn ? { sx: { width: '10px' } } : {};
              }}
              caption={
                <>
                  <CardContent sx={{ padding: '16px 24px' }}>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="subtitle" fontWeight="700">
                        <TransSubtitle i18nKey="searchResults" />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ ml: 2 }}
                      >
                        <TransSubtitle
                          i18nKey="businessCustomerQty"
                          values={{ num: data.length }}
                        />
                      </Typography>
                    </Stack>
                  </CardContent>
                </>
              }
              table={table}
            />
          </SearchResult>
        </Loadable>
      </Modal>
    );
  };
