import { FC, useCallback, useMemo } from 'react';
import {
  AddButton,
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  SearchForm,
  SelectField,
  SelectOwnerField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import {
  ALLOWED_BUSINESS_ENTITY_ROLES,
  ClassificationGroup,
} from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomersFilterSelector } from 'features/businessCustomer/businessCustomerSelectors';
import { BusinessCustomerFilter } from 'dto/businessCustomer';
import {
  getBusinessCustomers,
  setBusinessCustomersFilter,
} from 'features/businessCustomer/businessCustomerActions';
import { TransHeader } from 'i18n/trans/header';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';
import { pick } from 'lodash';

export const BusinessCustomerSearch: FC = () => {
  const organizationStatus = useClassificationOptions(
    ClassificationGroup.ORGANIZATION_STATUS
  );
  const dispatch = useDispatch();
  const businessEntities = useSelector(businessEntitiesSelector);
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(businessCustomersFilterSelector);

  const onSubmit = useCallback(
    (values: BusinessCustomerFilter) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(getBusinessCustomers({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const defaultValues = useMemo(
    () => ({ ownerId: currentBusinessEntityId }),
    [currentBusinessEntityId]
  );

  const initialValues = useMemo(
    () => ({ ...defaultValues, ...filter }),
    [defaultValues, filter]
  );

  const { form, handleSubmit } = useForm<BusinessCustomerFilter>({
    onSubmit,
    initialValues,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setBusinessCustomersFilter({
        ...defaultValues,
        ...pick(filter, ['limit', 'offset']),
      })
    );
  }, [form, dispatch, defaultValues, filter]);

  return (
    <SearchForm
      title={<TransHeader i18nKey="businessCustomer" />}
      action={
        <AddButton component={Link} to="/customers/business_customers/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={4}>
            <Grid item xs={1}>
              <SelectOwnerField
                businessEntities={businessEntities}
                allowedBusinessEntityTypes={ALLOWED_BUSINESS_ENTITY_ROLES}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="name"
                label={<TransField i18nKey="businessCustomerName" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="registrationCode"
                label={<TransField i18nKey="registrationCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="vatRegistrationCode"
                label={<TransField i18nKey="vatCode" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="contactPersonName"
                label={<TransField i18nKey="contactPerson" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                name="phoneNumber"
                label={<TransField i18nKey="phone" />}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField name="email" label={<TransField i18nKey="email" />} />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="statusIds"
                label={<TransField i18nKey="status" />}
                options={organizationStatus}
                multiple
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
